<template>
  <b-container>
    <div class="w">
      <div class="div-tittle">
        <span class="ml-3"> Deseja realmente </span>
        <span class="span-2">{{ `realizar a ${action} a seguir ?` }}</span>
      </div>
      <b-row>
        <b-col>
          <div class="box" v-if="hasBox">
            <b-row v-if="itens">
              <slot name="itensContent"></slot>
            </b-row>
            <b-row v-else-if="fixedContent">
              <slot name="fixedContent"></slot>
            </b-row>
          </div>
          <div v-if="hasObs" class="mt-4">
            <b-form-group label="Justificativa">
              <b-form-input
                v-model="observacao"
                size="lg"
                :state="Boolean(observacao)"
              >
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="6" class="exit-button">
        <Button
        id="button-exit"
        variant="grayscale"
        @click="exit"
        text="SAIR"
      />
      </b-col>
      <b-col>
        <Button
        id="button"
        @click="confirm"
        :text="buttonText"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Button from '@/components/Utils/Button';

export default {
  components: {
    Button,
  },

  data() {
    return {
      observacao: '',
    };
  },

  props: {
    itens: {
      type: Array,
      required: false,
    },
    hasBox: {
      type: Boolean,
      default: true,
    },
    action: {
      type: String,
      default: '',
    },
    hasObs: {
      type: Boolean,
      default: false,
    },
    fixedContent: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: 'Confirmar',
    },
  },

  methods: {
    confirm() {
      if (this.hasObs && this.observacao) {
        this.$emit('updateModel', this.observacao);
      }
      this.$emit('confirmAction');
    },

    exit() {
      this.$emit('wants-exit');
    },
  },
};
</script>

<style>
.nome-equipamento {
  font-weight: bold;
  font-size: 20px;
}
.div-tittle {
  padding: 10px 30px 15px 30px;
}
.box {
  border: 1px dashed #afb4d6;
  border-radius: 5px;
  padding: 2rem 0 1rem 2rem;
}

#button {
  margin-top: 2rem;
  float: right;
}

.span-2 {
  color: #21cca9;
}

.header .title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 40px;
  margin-left: -10px;
}

.exit-button {
  margin-top: 6.5%;
}
</style>
