export default function importScriptsZebra() {
  const newScript1 = document.createElement('script');
  newScript1.setAttribute('src', '/libs/BrowserPrint-3.0.216.min.js');

  const newScript2 = document.createElement('script');
  newScript2.setAttribute('src', '/libs/BrowserPrint-Zebra-1.0.216.min.js');

  document.head.appendChild(newScript1);
  document.head.appendChild(newScript2);
}
