<template>
  <div>
    <GenericForm
      title="material"
      previousRoute="material"
      editRoute="materialEditar"
      etiquetaRoute="materialEditar"
      :model="model"
      route="material"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_material"
      :permissionsToWrite="['rw_material']"
      :onlyVisualize="onlyVisualize"
      :permissionsToDelete="['rd_material']"
      :loadOnSave="true"
      :isMaterial="true"
      :refreshPageAfterSave="true"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-col v-if="isBusy">
          <b-col>
            <div class="barra-skeleton">
              <b-row align-v="center" v-for="i in 4" :key="i">
                <b-col sm="12" md="6" lg="6" xl="6" class="barra-skeleton">
                  <tb-skeleton
                    shape="rect"
                    style="background-color: #dcdcdc; height: 2rem; width: 100%"
                    theme="opacity"
                  ></tb-skeleton>
                </b-col>

                <b-col sm="12" md="6" lg="6" xl="6" class="barra-skeleton">
                  <tb-skeleton
                    shape="rect"
                    style="background-color: #dcdcdc; height: 2rem; width: 100%"
                    theme="opacity"
                  ></tb-skeleton>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-col>
        <div v-else>
          <ul
            class="material-nav"
            v-if="
              camposMaterial.composicao_itens &&
              camposMaterial.composicao_itens.necessario &&
              !model.isMaterialAvulso
            "
          >
            <li
              :class="tab === 'material' && 'nav-item-active'"
              @click="viewTabChange"
            >
              Cadastro de material
            </li>
            <li
              :class="tab === 'itens' && 'nav-item-active'"
              @click="viewTabChange"
            >
              Cadastro de itens
            </li>
          </ul>
          <div v-if="tab === 'material'">
            <div class="button-header">
              <div class="foto-procedimento-content">
                <div class="fotografia-button">
                  <Button
                    @click="openModalFoto"
                    variant="grayscale"
                    v-if="camposMaterial.fotografia"
                    :text="fotosButtonText"
                    icon="camera"
                  />
                </div>
                <div>
                  <Button
                    @click="openModalProcedimento"
                    v-if="campoIsEditable('procedimento')"
                    variant="grayscale"
                    :text="
                      editing ? 'Editar Procedimento' : 'Adicionar Procedimento'
                    "
                  />
                </div>
              </div>
            </div>
            <b-row align-v="center">
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  label="Tipo de material*"
                  label-for="tipoMaterial"
                >
                  <GenericSelect
                    name="tipoMaterial"
                    @input="changeFieldsRender"
                    labelKey="nome"
                    :value="idTipoMaterial"
                    :customFilters="TiposFilter"
                    route="tipoMaterial"
                    :disabled="
                      isBusy || onlyVisualize || tipoMaterial === 'medico'
                    "
                    v-validate="{ required: !editing }"
                    :state="validateState('tipoMaterial')"
                    :disabledFirstOption="idTipoMaterial ? true : false"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" v-if="acceptsAvulso">
                <b-form-group
                  v-if="campoIsEditable('isMaterialAvulso')"
                  label="Material Avulso*"
                  label-for="isMaterialAvulso"
                >
                  <YesOrNoRadio
                    name="isMaterialAvulso"
                    v-model="model.isMaterialAvulso"
                    v-validate="{ required: true }"
                    :state="validateState('isMaterialAvulso')"
                    :disabled="
                      editing || (model.itens && model.itens.length > 1)
                    "
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="Object.keys(camposMaterial).length">
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="
                  campoIsEditable('descricao') &&
                  (!model.cadastro_lote || editing)
                "
              >
                <b-form-group
                  :label="
                    !model.isMaterialAvulso
                      ? camposMaterial.descricao.descricao + '*'
                      : 'Tipo de instrumento*'
                  "
                  label-for="descricao"
                >
                  <InputWithSuggestions
                    v-if="!model.isMaterialAvulso"
                    route="material/materiaisPorDescricao"
                    propertyName="descricao"
                    id="descricao"
                    name="descricao"
                    :disabled="isBusy || onlyVisualize"
                    v-model="model.descricao"
                    v-validate="{
                      required: camposMaterial.descricao.necessario,
                    }"
                    :state="validateState('descricao')"
                    item-key-identifier="id_material"
                    item-content-identifier="descricao"
                    :min-search-length="3"
                    :max-item-length="30"
                  />
                  <GenericSelectSearch
                    v-else
                    route="tipoItem/listWithSearch"
                    name="tipoItemSelect"
                    labelKey="descricao"
                    idKey="id_tipo_item"
                    :initialLabel="model.descricao"
                    @input="instrumentoSelect"
                    :disabled="disableGenericSelectSearch"
                    :value="model.tipoItem"
                    :minCharSearch="idTipoMaterial === 7 ? 0 : 3"
                    :filters="idTipoMaterial === 7 ? filterTipoItemInox : null"
                    v-validate="{ required: true }"
                    :state="validateState('tipoItemSelect')"
                    :searchInitial="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="model.isMaterialAvulso && !model.cadastro_lote"
              >
                <b-form-group label="Fabricante" label-for="fabricante">
                  <GenericSelect
                    ref="fabricante"
                    name="fabricante"
                    labelKey="nome"
                    key="fabricante"
                    v-model="model.id_fabricante"
                    route="fabricante"
                    :disabled="isBusy || onlyVisualize"
                    v-validate="{ required: false }"
                    :state="validateState('fabricante')"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="
                  campoIsEditable('quantidade_itens') &&
                  (!model.cadastro_lote || editing)
                "
              >
                <b-form-group
                  :label="camposMaterial.quantidade_itens.descricao"
                  label-for="quantidade_itens"
                >
                  <b-form-input
                    id="quantidade_itens"
                    type="number"
                    min="0"
                    v-model="model.quantidade_itens"
                    autocomplete="off"
                    class="invision-input"
                    name="quantidade_itens"
                    :disabled="
                      isBusy ||
                      onlyVisualize ||
                      (!model.isMaterialAvulso &&
                        model.itens &&
                        model.itens.length > 0)
                    "
                    v-validate="
                      `required:${camposMaterial.quantidade_itens.necessario}|greaterThanZero`
                    "
                    :state="validateState('quantidade_itens')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="
                  !model.cadastro_lote &&
                  campoIsEditable('limite_ciclos')
                "
              >
                <b-form-group
                  :label="camposMaterial.limite_ciclos.descricao"
                  label-for="limite_ciclos"
                >
                  <b-form-input
                    id="limite_ciclos"
                    type="number"
                    min="0"
                    v-model="model.limite_ciclos"
                    autocomplete="off"
                    class="invision-input"
                    name="limite_ciclos"
                    :disabled="
                      isBusy ||
                      onlyVisualize ||
                      (editing && !verifyPermissions(['rw_limite_ciclos']))
                    "
                    v-validate="{ required: camposMaterial.limite_ciclos.necessario, min_value: 0 }"
                    :state="validateState('limite_ciclos')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="campoIsEditable('id_fornecedor')"
              >
                <b-form-group label="Fornecedor" label-for="fornecedor">
                  <GenericSelect
                    name="fornecedor"
                    labelKey="nome"
                    key="fornecedor"
                    v-model="model.id_fornecedor"
                    route="fornecedor"
                    :disabled="isBusy || onlyVisualize"
                    v-validate="{
                      required: camposMaterial.id_fornecedor.necessario,
                    }"
                    :state="validateState('fornecedor')"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="campoIsEditable('id_unidade_patrimonio')"
              >
                <b-form-group
                  :label="camposMaterial.id_unidade_patrimonio.descricao + '*'"
                  label-for="unidade"
                >
                  <GenericSelect
                    name="unidade"
                    labelKey="nome"
                    v-model="model.id_unidade_patrimonio"
                    key="unidade"
                    route="unidade"
                    :disabled="isBusy || onlyVisualize"
                    v-validate="{
                      required: camposMaterial.id_unidade_patrimonio.necessario,
                    }"
                    :state="validateState('unidade')"
                    @input="inputUnidade"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="campoIsEditable('id_setor')"
              >
                <b-form-group
                  :label="camposMaterial.id_setor.descricao"
                  label-for="setor"
                  label-class="float-left"
                >
                  <info-icon
                    v-b-tooltip.hover.right="
                      'Insira o nome do setor ao qual o material pertence'
                    "
                    class="tooltip-icon"
                  />
                  <GenericSelect
                    ref="setor"
                    name="setor"
                    labelKey="nome"
                    v-model="model.id_setor"
                    @input="inputSetor"
                    route="setor"
                    :customFilters="setorSelectFilters"
                    :disabled="isBusy || onlyVisualize"
                    v-validate="{
                      required: camposMaterial.id_setor.necessario,
                    }"
                    :state="validateState('setor')"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="campoIsEditable('id_tipo_embalagem')"
              >
                <b-form-group
                  :label="camposMaterial.id_tipo_embalagem.descricao"
                  label-for="tipoEmbalagem"
                >
                  <GenericSelect
                    name="tipoEmbalagem"
                    labelKey="nome"
                    v-model="model.id_tipo_embalagem"
                    route="tipoEmbalagem"
                    :disabled="isBusy || onlyVisualize"
                    v-validate="{
                      required: camposMaterial.id_tipo_embalagem.necessario,
                    }"
                    :state="validateState('tipoEmbalagem')"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.cor">
                <b-form-group
                  label="Cor"
                  label-for="cor"
                  label-class="float-left"
                >
                  <b-form-input
                    id="cor"
                    name="cor"
                    v-model="model.cor"
                    class="invision-input input-icon"
                    :disabled="isBusy"
                    v-validate="{ required: false }"
                    :state="validateState('cor')"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.marca">
                <b-form-group
                  label="Marca"
                  label-for="marca"
                  label-class="float-left"
                >
                  <b-form-input
                    id="marca"
                    name="marca"
                    v-model="model.marca"
                    class="invision-input input-icon"
                    :disabled="isBusy"
                    v-validate="{ required: false }"
                    :state="validateState('marca')"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.numero_serie">
                <b-form-group
                  label="Número de série"
                  label-for="numero_serie"
                  label-class="float-left"
                >
                  <b-form-input
                    id="numero_serie"
                    name="numero_serie"
                    v-model="model.numero_serie"
                    class="invision-input input-icon"
                    :disabled="isBusy"
                    v-validate="{ required: false }"
                    :state="validateState('numero_serie')"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.dupla_checagem">
                <b-form-group
                  :label="camposMaterial.dupla_checagem.descricao"
                  label-for="dupla_checagem"
                >
                  <ToggleSwitch
                    name="dupla_checagem"
                    ref="dupla_checagem"
                    showText
                    :value="model.dupla_checagem"
                    @toggle="(v) => (model.dupla_checagem = v)"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.aviso_recebimento">
                <b-form-group
                  :label="camposMaterial.aviso_recebimento.descricao"
                  label-for="aviso_recebimento"
                >
                  <ToggleSwitch
                    name="aviso_recebimento"
                    ref="aviso_recebimento"
                    showText
                    :value="model.aviso_recebimento"
                    @toggle="(v) => (model.aviso_recebimento = v)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="campoIsEditable('id_medico')"
              >
                <b-form-group
                  :label="camposMaterial.id_medico.descricao"
                  label-for="nomeCirurgiao"
                >
                  <GenericSelect
                    name="nomeCirurgiao"
                    labelKey="nome"
                    v-model="model.id_medico"
                    route="medico"
                    :disabled="isBusy"
                    v-validate="{
                      required: camposMaterial.id_medico.necessario,
                    }"
                    :state="validateState('nomeCirurgiao')"
                  ></GenericSelect>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                v-if="
                  campoIsEditable('codigo') &&
                  !model.isMaterialAvulso &&
                  !model.cadastro_lote
                "
              >
                <b-form-group
                  :label="camposMaterial.codigo.descricao"
                  label-for="codigo"
                >
                  <b-form-input
                    id="codigo"
                    v-model="model.codigo"
                    autocomplete="off"
                    class="invision-input"
                    name="codigo"
                    :disabled="isBusy || onlyVisualize"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="campoIsEditable('cadastro_lote')
                && !model.isMaterialAvulso
                && !model.itens.length"
            >
              <b-col sm="12" md="12" lg="12" xl="12">
                <YesOrNoRadio
                  name="cadastroEmLote"
                  :label="camposMaterial.cadastro_lote.descricao"
                  v-model="model.cadastro_lote"
                  :disabled="editing || isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('cadastroEmLote')"
                />
              </b-col>
            </b-row>
            <b-row v-if="model.isMaterialAvulso && !model.cadastro_lote">
              <b-col sm="12" md="12" lg="12" xl="12">
                <b-form-group
                  label="Leitura do código do material avulso (utilize o leitor)"
                  label-for="codeAvulso"
                >
                  <b-form-input
                    id="codeAvulso"
                    v-model="model.codeAvulso"
                    autocomplete="off"
                    class="invision-input"
                    name="codeAvulso"
                    :disabled="isBusy || onlyVisualize"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="model.cadastro_lote && !editing">
              <b-col>
                <DescricoesTable
                  :lote="model.lote"
                  :hasQntItens="
                    camposMaterial.quantidade_itens &&
                    camposMaterial.quantidade_itens.editavel
                  "
                  @addCaixa="addCaixa"
                  @removeCaixa="removeCaixa"
                  @update-lote="updateLote"
                />
              </b-col>
            </b-row>
            <b-row
              v-if="camposMaterial.tipo_processamento"
            >
              <b-col sm="12" md="6" lg="6" xl="6">
                <b-form-group
                  label-class="float-left"
                  style="border-style: solid;
                    border-color: rgb(215, 211, 211);
                    border-width: 1px;
                    border-radius: 7px;
                    padding: 10px;
                  "
                  :label=camposMaterial.tipo_processamento.descricao
                  :label-for=camposMaterial.tipo_processamento.descricao
                >
                  <info-icon
                    v-if="!campoIsEditable('tipo_processamento')"
                    v-b-tooltip.hover.right="
                      'Não é permitido realizar alterações'
                    "
                    class="tooltip-icon"
                  />
                  <br><br>
                  <b-row cols="12">
                    <b-col cols="4">
                      <b-form-group
                        style="margin-left: 8px;"
                        label="Preparo"
                      />
                    </b-col>
                    <b-col cols="8">
                      <GenericMultipleSelect
                        name="tipoLavagem"
                        v-validate="{ required: camposMaterial.tipo_processamento.necessario }"
                        ref="tipoLavagem"
                        labelKey="descricao"
                        :value="model.tipo_processamento.tipo_lavagem"
                        :state="validateState('tipoLavagem')"
                        route="tipoLavagem"
                        :disabled="!campoIsEditable('tipo_processamento')"
                        @input="state => changeTiposProcessamentos(
                          'tipo_lavagem',
                          state,
                        )"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        style="margin-left: 8px;"
                        label="Esterilização"
                      />
                    </b-col>
                    <b-col cols="8">
                      <GenericMultipleSelect
                        name="tipoEsterilizacao"
                        v-validate="{ required: camposMaterial.tipo_processamento.necessario }"
                        ref="tipoEsterilizacao"
                        labelKey="nome"
                        :value="model.tipo_processamento.tipo_esterilizacao"
                        :state="validateState('tipoEsterilizacao')"
                        route="tipoEsterilizacao"
                        :disabled="!campoIsEditable('tipo_processamento')"
                        @input="state => changeTiposProcessamentos(
                          'tipo_esterilizacao',
                          state,
                        )"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <ComposicaoItens
            v-if="
              camposMaterial.composicao_itens &&
              camposMaterial.composicao_itens.necessario &&
              tab === 'itens'
            "
            :model="model"
            @update-model="updateModelWithItens"
            @open-modal-foto="openModalFoto"
          />
          <Modal
            id="modalPhoto"
            ref="modalPhoto"
            nome="modalPhoto"
            :title="editing ? 'Fotos do Material' : 'Editar fotos do material'"
            no-close-on-esc
            no-close-on-backdrop
            size="md"
            centered
            @cancel="closeModalFoto"
          >
            <PhotoForm
              :photos="modalPhotos"
              :buttonConfirmar="true"
              downloadRoute="material/downloadFotos"
              :onlyVisualize="model.onlyVisualizeFotos"
              @newPhoto="setImage"
              @confirmar="closeModalFoto"
              :photosName="photosName"
            />
          </Modal>
          <Modal
            id="modalProcedimento"
            ref="modalProcedimento"
            nome="modalProcedimento"
            title="Adicionar procedimento para o material"
            no-close-on-esc
            no-close-on-backdrop
            size="lg"
            centered
          >
            <Procedimento
              :materialModel="model"
              :validateState="validateState"
              ref="procedimento"
              @close-modal="closeModalProcedimento"
              @update-material="updateMaterial"
            />
          </Modal>
          <Modal
            id="modalLote"
            ref="modalLote"
            nome="modalLote"
            title="Cadastro de caixas do Lote"
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            size="md"
            centered
            @hidden="handleHideModel"
          >
            <b-container>
              <b-row>
                <b-col>
                  <b-form-group
                    label-cols="12"
                    :label="
                      model.id_tipo_material !== 7 && idTipoMaterial !== 7
                        ? 'Descricao*'
                        : 'Tipo Inox'
                    "
                    label-for="descricaoCaixa"
                  >
                    <InputWithSuggestions
                      v-if="
                        model.id_tipo_material !== 7 && idTipoMaterial !== 7
                      "
                      route="material/materiaisPorDescricao"
                      propertyName="descricaoCaixa"
                      id="descricaoCaixa"
                      name="descricaoCaixa"
                      v-model="descricao_caixas"
                      v-validate="{
                        required: descricao_caixas,
                      }"
                      :state="
                        descricao_caixas.trim() === null
                          ? null
                          : Boolean(descricao_caixas.trim())
                      "
                      item-content-identifier="descricao"
                      :min-search-length="3"
                      :max-item-length="30"
                    />
                    <GenericSelect
                      v-else
                      route="tipoItem"
                      ref="tipoItemInox"
                      name="tipoItem"
                      labelKey="descricao"
                      idKey="id_tipo_item"
                      @input="instrumentoSelectOnLote"
                      :customFilters="filterTipoItemInox"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="campoIsEditable('quantidade_itens')">
                  <b-form-group
                    label-cols="12"
                    label="Quantidade de Itens por Caixa*"
                    label-for="quantidadeItensCaixa"
                  >
                    <b-form-input
                      id="quantidadeItensCaixa"
                      type="number"
                      min="1"
                      v-model="quantidade_itens"
                      autocomplete="off"
                      class="invision-input"
                      name="quantidadeItensCaixa"
                      v-validate="
                        `required:${quantidade_itens}|greaterThanZero`
                      "
                      :state="
                        quantidade_itens === null
                          ? null
                          : Boolean(+quantidade_itens)
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label-cols="colsQuantidadeCaixas"
                    :label="
                      idTipoMaterial === 7
                        ? 'Quantidade*'
                        : 'Quantidade de Caixas*'
                    "
                    label-for="quantidadeCaixas"
                  >
                    <b-form-input
                      id="quantidadeCaixas"
                      type="number"
                      min="1"
                      v-model="quantidade_caixas"
                      autocomplete="off"
                      class="invision-input"
                      name="quantidadeCaixas"
                      v-validate="
                        `required:${quantidade_caixas}|greaterThanZero`
                      "
                      :state="
                        quantidade_caixas === null
                          ? null
                          : Boolean(+quantidade_caixas)
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <Button @click="saveCaixa" text="Salvar Caixa" />
                </b-col>
              </b-row>
            </b-container>
          </Modal>
          <Modal
            id="modalTrocaTipoMaterial"
            ref="modalTrocaTipoMaterial"
            nome="modalTrocaTipoMaterial"
            title="Alteração de tipo de material"
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            size="md"
            centered
          >
            <div>
              <Confirmacao
                action="alteração"
                :fixedContent="true"
                @confirmAction="confirmChange"
              >
                <template #fixedContent>
                  <div class="box-change-material-type">
                    Alterar o tipo de material pode acarretar em mudanças
                    significativas no fluxo do material. Deseja continuar?
                  </div>
                </template>
              </Confirmacao>
            </div>
          </Modal>
        </div>
      </template>

      <template
        #buttons="{ isBusy, cancel, exclude, onSubmit, exclusionEnabled }"
      >
        <b-row align-h="between" class="pt-4">
          <b-col>
            <Button
              variant="grayscale"
              @click="cancel"
              :class="editing && exclusionEnabled ? 'rounded left' : ''"
              text="Cancelar"
              :disabled="isBusy"
            />
            <Button
              v-if="editing && !notDeletable"
              variant="grayscale"
              type="text"
              class="rounded right"
              @click="exclude"
              :disabled="isBusy"
              text="Excluir"
            />
          </b-col>
          <b-col class="text-right">
            <Button
              :disabled="isBusy"
              text="Salvar"
              @click="customSubmit(onSubmit)"
            />
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import genericRequest from '@/services/genericRequest';
import loginService from '@/services/login';
import unidadeService from '@/services/unidade';

import GenericForm from '@/components/Form/GenericForm';
import GenericSelect from '@/components/Form/GenericSelect';
import GenericSelectSearch from '@/components/Form/GenericSelectSearch';
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';
import Modal from '@/components/Utils/Modal';
import Confirmacao from '@/components/Utils/Confirmacao';
import Button from '@/components/Utils/Button';
import PhotoForm from '@/components/WebCam/PhotoForm';

import DescricoesTable from './DescricoesTable';
import Procedimento from './Procedimento';
import ComposicaoItens from './ComposicaoItens';
import InputWithSuggestions from '../../components/Form/InputWithSuggestions';
import ToggleSwitch from '../../components/Form/ToggleSwitch';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';

const idsTipoMaterialCadastroRapido = [2, 3, 4];

export default {
  components: {
    GenericForm,
    GenericSelect,
    YesOrNoRadio,
    DescricoesTable,
    Modal,
    PhotoForm,
    Procedimento,
    GenericSelectSearch,
    Confirmacao,
    Button,
    ComposicaoItens,
    InputWithSuggestions,
    ToggleSwitch,
    GenericMultipleSelect,
  },

  provide() {
    return { parentValidator: this.$parent.$validator };
  },

  props: {
    tipoMaterial: {
      type: String,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['currentUnidade', 'configuracao']),

    setorSelectFilters() {
      if (this.model.id_unidade_patrimonio) {
        return { id_unidade: this.model.id_unidade_patrimonio };
      }

      return { id_unidade: 0 };
    },

    isCME() {
      return this.id_setor_CME === this.model.id_setor;
    },

    onlyVisualize() {
      const permissions = idsTipoMaterialCadastroRapido.includes(
        this.idTipoMaterial,
      )
        ? loginService.verifyPermissions(['rw_cadastro_rapido'])
        : loginService.verifyPermissions(['re_material']);
      return !permissions && this.editing;
    },

    TiposFilter() {
      if (this.editing) return {};
      if (this.tipoMaterial === 'OPME') return { onlyTipos: ['OPME Comodato'] };
      if (this.tipoMaterial === 'medico') return { onlyTipos: ['Médico'] };
      const user = loginService.getUser();
      if (user.idTipoMaterial) {
        return {
          id_tipo_material: user.idTipoMaterial,
          withoutTipos: ['OPME Consignado'],
        };
      }
      return { withoutTipos: ['Médico', 'OPME Consignado', 'Enxoval'] };
    },

    colsQuantidadeCaixas() {
      if (
        this.camposMaterial.quantidade_itens
        && this.camposMaterial.quantidade_itens.editavel
      ) {
        return '10';
      }
      return '12';
    },

    acceptsAvulso() {
      if (this.model.cadastro_lote && !this.model.id_item_avulso) {
        return false;
      }
      return Boolean(this.camposMaterial.composicao_itens);
    },

    photosName() {
      if (this.model.descricao || this.model.id_material !== -1) {
        return `${this.model.descricao}_ID-${this.model.id_material}`;
      }
      return 'material';
    },

    fotosButtonText() {
      let text = this.editing
        || this.model.onlyVisualizeFotos
        ? 'Visualizar fotografias'
        : 'Inserir fotografias';
      if (this.model.fotos.length) {
        text = `${text} (${this.model.fotos.length})`;
      }
      return text;
    },
  },

  async created() {
    await this.getPermisionsExtendedDeleteMaterials();

    if (this.tipoMaterial === 'medico') {
      this.model.id_tipo_material = 2; // id_tipo_material do médico
      this.idTipoMaterial = 2;
    }
  },

  data() {
    return {
      tab: 'material',
      model: {
        id_material: this.$route.params.id || -1,
        descricao: '',
        id_unidade_patrimonio: null,
        id_tipo_material: null,
        id_classe_material: null,
        id_tipo_embalagem: null,
        quantidade_itens: null,
        limite_ciclos: null,
        quantidade_atual_itens: null,
        id_medico: null,
        id_fornecedor: null,
        id_fabricante: null,
        id_setor: null,
        forms: [],
        tipo_liberacao: null,
        id_lote_saida_material: null,
        cadastro_lote: false,
        lote: [],
        fotos: [],
        isMaterialAvulso: false,
        codeAvulso: '',
        procedimento: {},
        tipoItem: {},
        onlyVisualizeFotos: false,
        aviso: '',
        dupla_checagem: true,
        codigo: null,
        cor: '',
        aviso_recebimento: false,
        tipo_processamento: {
          tipo_lavagem: [],
          tipo_esterilizacao: [],
        },
      },
      newItemCode: '',
      loading: false,
      modalPhotos: [],
      currentPageChangeMaterialType: 0,
      oldIdTipoMaterial: null,
      newIdTipoMaterial: null,
      id_setor_CME: null,
      id_setor_bloco: null,
      newPropertiesForModel: {},
      idTipoMaterial: null,
      isVideoCirurgia: false,
      camposMaterial: {},
      cadastro_lote: {
        editavel: true,
        necessario: true,
        padrao: false,
      },
      quantidade_caixas: null,
      descricao_caixas: '',
      quantidade_itens: null,
      tipoItem: {},
      filterTipoItemInox: {
        id_tipo_material: 7,
      },
      disableGenericSelectSearch: false,
      onlyVisualizeFotosOriginal: false,
      deletedMaterialsWithCycle: false,
      notDeletable: false,
    };
  },

  watch: {
    async idTipoMaterial(val) {
      if (!this.editing) {
        this.model = {
          ...this.model,
          id_tipo_material: val,
          cadastro_lote: false,
          isMaterialAvulso: false,
          tipo_liberacao: null,
          id_tipo_embalagem: null,
          quantidade_itens: null,
          aviso_recebimento: false,
          itens: [],
        };
        await this.updateCamposMaterial(val);
        this.onlyVisualizeFotosOriginal = this.model.onlyVisualizeFotos;
      }

      await this.updateTiposProcessamento(val);
      //! TODO Verificação de Tipo Item
      const tipo = await genericRequest.getWithoutPagination(
        {},
        `tipoMaterial/${val}`,
      );
      if (tipo.nome === 'Vídeo Cirurgia') {
        this.isVideoCirurgia = true;
      } else {
        this.isVideoCirurgia = false;
      }
      this.newIdTipoMaterial = val;
    },

    'model.isMaterialAvulso': function isAvulsoChange(v) {
      this.model = {
        ...this.model,
        onlyVisualizeFotos: v,
        descricao:
          (this.model.itemAvulso && this.model.itemAvulso.descricao) || '',
        quantidade_itens:
          (this.model.itemAvulso && this.model.itemAvulso.quantidade_pecas)
          || (this.camposMaterial.quantidade_itens
          && this.camposMaterial.quantidade_itens.padrao
          && (!this.camposMaterial.quantidade_itens.editavel
          || !this.editing)
          && this.camposMaterial.quantidade_itens.padrao)
          || null,
        fotos: [],
        codeAvulso: (this.model.itemAvulso && this.model.itemAvulso.code) || '',
        id_fabricante:
          (this.model.itemAvulso && this.model.itemAvulso.id_fabricante) || null,
      };
      if (v && this.tab !== 'material') {
        this.tab = 'material';
      }
    },

    'model.itens': function itensChange(v, oldV) {
      if (!v) return;
      if (!oldV || oldV.length !== v.length) {
        if (v.length > 1) {
          this.model.isMaterialAvulso = false;
        }
        if (v.length > 0) {
          this.model.quantidade_itens = v.reduce(
            (acum, curr) => acum + +curr.quantidade_pecas_item,
            0,
          );
        }
        if (
          oldV
          && oldV.length
          && this.model.quantidade_itens
          && v.length <= 0
        ) {
          this.model.quantidade_itens = null;
        }
      }
    },
  },

  methods: {
    viewTabChange() {
      if (this.tab === 'material') {
        this.model.onlyVisualizeFotos = true;
        this.tab = 'itens';
      } else {
        this.model.onlyVisualizeFotos = this.onlyVisualizeFotosOriginal;
        this.tab = 'material';
      }
    },

    updateModelWithItens(prop, value) {
      this.model = {
        ...this.model,
        [prop]: value,
        quantidade_pecas: value.length,
        quantidade_itens: value.reduce(
          (sum, currentValue) => sum + +currentValue.quantidade_pecas_item,
          0,
        ),
        quantidade_atual_itens: value.reduce(
          (sum, currentValue) => sum + +currentValue.quantidade_pecas_item,
          0,
        ),
      };
    },

    inputSetor($event) {
      if (this.id_setor_bloco === +$event) {
        this.model.tipo_liberacao = 'setor';
      } else if (this.id_setor_CME === +$event) {
        this.model.tipo_liberacao = 'cme';
      } else {
        this.model.tipo_liberacao = 'voucher';
      }
    },

    updateLote(lote) {
      this.model.lote.forEach((el, index) => {
        if (el.descricao_caixas !== lote[index].descricao_caixas) {
          el.descricao_caixas = lote[index].descricao_caixas;
        }
      });
    },

    confirmChange() {
      this.model.id_tipo_material = this.newIdTipoMaterial;
      this.resetOldRequiredFields();
      this.updateCamposMaterial(this.model.id_tipo_material);
      this.$refs.modalTrocaTipoMaterial.hide();
    },

    resetOldRequiredFields() {
      if (!this.campoIsEditable('id_fornecedor')) {
        this.model.id_unidade_patrimonio = null;
        this.model.id_setor = null;
        this.model.id_medico = null;
        this.model.quantidade_itens = null;
      } else {
        this.model.id_fornecedor = null;
        this.model.id_unidade = null;
      }
    },

    changeFieldsRender(v) {
      this.idTipoMaterial = v;
      this.$validator.pause();
      if (this.editing) {
        this.$refs.modalTrocaTipoMaterial.show();
      }
    },
    async updateModel(model) {
      const {
        lastCiclo,
        stringZebra,
        fornecedor_nome,
        medico_nome,
        ...restModel
      } = model;
      this.model = restModel;
      this.idTipoMaterial = this.model.id_tipo_material;
      this.oldIdTipoMaterial = this.model.id_tipo_material;
      if (model.id_item_avulso) {
        this.model.isMaterialAvulso = true;
        this.model.codeAvulso = model.itemAvulso.code;
        this.model.quantidade_itens = model.itemAvulso.quantidade_pecas;
      }
      await this.updateCentralEsterilizacao(model.id_unidade_patrimonio);
      await this.updateCamposMaterial(this.idTipoMaterial);
      this.$emit('modelUpdated', {
        lastCiclo,
        stringZebra,
        fornecedor_nome,
        medico_nome,
      });
    },
    campoIsEditable(campo) {
      return this.camposMaterial[campo] && this.camposMaterial[campo].editavel;
    },
    updateMaterialProcedimento(campo, val) {
      this.model.procedimento[campo] = val;
    },
    updateMaterial(campo, val) {
      this.model[campo] = val;
    },
    inputUnidade(val) {
      this.model.id_unidade_patrimonio = val;
      if (val) this.updateCentralEsterilizacao(val);
    },
    instrumentoSelect(itemSelected) {
      this.model.tipoItem = itemSelected;
      this.model.descricao = itemSelected ? itemSelected.descricao : '';
      this.model.fotos = itemSelected ? itemSelected.fotoTipoItem : [];
      // this.openModalFoto();
    },
    setImage(images) {
      this.model.fotos = images;
    },
    instrumentoSelectOnLote(idItemSelected) {
      const item = this.$refs.tipoItemInox.apiResponse.find(
        (i) => i.id_tipo_item === idItemSelected,
      );
      this.descricao_caixas = item && item.descricao;
      this.tipoItem = item;
    },
    validateDescricao() {
      if (!this.descricao_caixas.trim()) this.descricao_caixas = '';
    },

    saveCaixa() {
      if (this.model.id_tipo_material !== 7) {
        if (
          !+this.quantidade_caixas
          || !+this.quantidade_itens
          || !this.descricao_caixas.trim()
        ) {
          if (!+this.quantidade_caixas) this.quantidade_caixas = 0;
          if (!+this.quantidade_itens) this.quantidade_itens = 0;
          if (!this.descricao_caixas.trim()) this.descricao_caixas = '';
          return;
        }
      } else if (!+this.quantidade_caixas || !this.descricao_caixas) {
        if (!+this.quantidade_caixas) this.quantidade_caixas = 0;
        return;
      }
      this.model.lote.push({
        quantidade_itens: this.quantidade_itens,
        quantidade_caixas: this.quantidade_caixas,
        descricao_caixas: this.descricao_caixas,
        tipoItem: this.tipoItem,
      });
      this.$refs.modalLote.hide();
    },
    addCaixa() {
      this.$refs.modalLote.show();
    },
    openModalFoto(urls) {
      this.modalPhotos = urls || this.model.fotos;
      this.$refs.modalPhoto.show();
    },
    closeModalFoto() {
      this.disableGenericSelectSearch = true;
      setTimeout(() => {
        this.disableGenericSelectSearch = false;
      }, 500);
      this.modalPhotos = [];
      this.$refs.modalPhoto.hide();
    },
    confirmarProcedimento() {
      this.$refs.procedimento.confirmar();
    },
    openModalProcedimento() {
      this.$refs.modalProcedimento.show();
    },
    closeModalProcedimento() {
      this.$refs.modalProcedimento.hide();
    },
    removeCaixa(indexCaixa) {
      this.model.lote.splice(indexCaixa, 1);
    },
    handleHideModel() {
      this.quantidade_caixas = null;
      this.descricao_caixas = '';
      this.quantidade_itens = null;
    },
    async updateCamposMaterial(idTipoMaterial) {
      const route = `tipoMaterial/CampoMaterial/${idTipoMaterial}`;
      const camposMaterial = await genericRequest.getWithoutPagination(
        {},
        route,
      );
      this.camposMaterial = camposMaterial;
      this.updateModelByTipo();
    },
    async updateCentralEsterilizacao(id_unidade) {
      if (!id_unidade) return;
      const blocoAndCme = await unidadeService.getBlocoAndCmeByIdUnidade(
        id_unidade,
      );
      blocoAndCme.forEach((el) => {
        if (el.UnidadeSetor.bloco) {
          this.id_setor_bloco = el.id_setor;
        } else if (el.UnidadeSetor.central_esterilizacao) {
          this.id_setor_CME = el.id_setor;
        }
      });
    },
    updateModelByTipo() {
      const newModelValues = {};
      Object.keys(this.camposMaterial).forEach((chave) => {
        const { padrao, editavel } = this.camposMaterial[chave];
        if (padrao && (!editavel || !this.editing)) {
          if (padrao === 'true' || padrao === 'false') {
            newModelValues[chave] = eval(padrao); // eslint-disable-line no-eval
          } else {
            newModelValues[chave] = Number.isNaN(Number(padrao))
              ? padrao
              : Number(padrao);
          }
        }
      });
      if (Object.keys(newModelValues).length) {
        this.model = { ...this.model, ...newModelValues };
      }
    },
    async validateAll() {
      this.$validator.resume();
      const campoMaterialFaltante = [];

      return [!Object.keys(this.camposMaterial).some((key) => {
        if (
          this.camposMaterial[key].editavel
          && this.camposMaterial[key].necessario
          && !this.model[key]
          && !this.model.cadastro_lote
        ) {
          campoMaterialFaltante.push(` ${this.camposMaterial[key].descricao}`);
        } else if (this.model[key] === this.model.quantidade_itens && this.model[key] <= 0) {
          campoMaterialFaltante.push(this.camposMaterial[key].descricao);
        }

        if (
          key === 'descricao'
          && this.model.cadastro_lote
          && !this.editing
          && (!this.model.lote
            || !this.model.lote.length
            || this.model.lote.some(
              (mat) => !mat.descricao_caixas || !+mat.quantidade_caixas,
            ))
        ) {
          campoMaterialFaltante.push(` ${this.camposMaterial[key].descricao}`);
        }

        return false;
      }), campoMaterialFaltante];
    },
    async customSubmit(submitFunc) {
      const validation = await this.validateAll();
      if (validation[1].length) {
        swal({
          icon: 'error',
          title: 'Campos não preenchidos corretamente',
          text: `Campo de ${validation[1]} não preenchido(s). Favor verificá-los antes de salvar.`,
          buttons: { confirm: 'Continuar' },
        });
        return;
      }
      const codeItemAvulsoPertenceOutroMaterial = await this.validateCodeItemAvulsoPertenceOutroMaterial();
      if (codeItemAvulsoPertenceOutroMaterial) return;
      submitFunc();
    },
    verifyPermissions(permissions) {
      return loginService.verifyPermissions(permissions);
    },
    async validateCodeItemAvulsoPertenceOutroMaterial() {
      if (this.model.isMaterialAvulso && this.model.codeAvulso) {
        let itemExiste = false;
        const itens = await genericRequest.get({}, `item/Composicao/${this.model.codeAvulso}`);
        let codeItemAlterado = false;
        itemExiste = Boolean(itens && itens.length);
        if (this.editing) {
          codeItemAlterado = this.model.codeAvulso
            ? Boolean(this.model.itemAvulso.code !== this.model.codeAvulso) : false;
        }

        if ((itemExiste && !this.editing) || (codeItemAlterado && this.editing && itemExiste)) {
          swal({
            icon: 'error',
            title: 'Código Existe!',
            text: `
              O código informado pertence ao material com ID: ${itens && itens.length ? itens[0].material.id : ''}
            `,
            buttons: { confirm: 'Continuar' },
          });
          return true;
        }
      }
      return false;
    },

    async updateTiposProcessamento(val) {
      if (this.camposMaterial.tipo_processamento || this.editing) {
        const tiposProcessamento = await genericRequest.getWithoutPagination(
          {}, `tipoMaterial/tipoProcessamento/${val}`,
        );
        if (!this.editing) {
          this.model.tipo_processamento.tipo_esterilizacao = tiposProcessamento.tipoEsterilizacao.map((v) => ({
            label: v.label,
            value: v.value,
          })) || [];

          this.model.tipo_processamento.tipo_lavagem = tiposProcessamento.tipoLavagem.map((v) => ({
            label: v.label,
            value: v.value,
          })) || [];
        }
      }
    },
    changeTiposProcessamentos(ref, value) {
      this.model.tipo_processamento[ref] = value.map((v) => ({
        value: v.value,
        label: v.label,
      }));
    },
    async getPermisionsExtendedDeleteMaterials() {
      if (!this.editing) return;
      this.deletedMaterialsWithCycle = this.configuracao.deletedMaterialsWithCycle
        && this.configuracao.deletedMaterialsWithCycle === 'true';
      if (!this.deletedMaterialsWithCycle) {
        const idMaterial = this.model.id_material;
        this.notDeletable = Boolean((await genericRequest.getWithoutPagination(
          {},
          `ciclo/processosByCiclo/${idMaterial}`,
        )).id_ciclo);
      }
    },
  },
};
</script>

<style scoped>
.row-edit-button {
  background: none;
  color: #35384d;
}
.row-trash-button {
  background: none;
  color: #35384d;
}
.row-trash-button:hover {
  color: rgb(158, 39, 39);
}
.table-row:hover {
  background: #f7f7fa;
  cursor: pointer;
}
.table-pecas-button {
  background: none;
  color: #7e829f;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.box-change-material-type {
  font-size: 15px;
  margin-bottom: 10px;
  color: #21cca9;
}
.button-procedimento {
  position: absolute;
  bottom: 1px;
  left: 340px;
  z-index: 2;
}
.button-procedimento-editing {
  position: absolute;
  bottom: 1px;
  left: 465px;
  z-index: 2;
}
.num-photos {
  align-self: flex-start;
  padding-left: 10px;
}
.tooltip-icon {
  width: 16px;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
  color: #35384d;
}
.material-nav {
  list-style: none;
  padding-inline-start: 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cfd1e6;
  margin-bottom: 36px;
}
.material-nav li {
  color: #7e829f;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 24px 14px 24px;
}
.nav-item-active {
  border-bottom: 3px solid #209f85 !important;
  color: #209f85 !important;
  margin-bottom: -2.5px;
}
.info-yes-no {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #35384d;
}
.button-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.foto-procedimento-content {
  display: flex;
  align-items: center;
}
.fotografia-button {
  margin-right: 1rem;
}

.barra-skeleton {
  margin-bottom: 3rem;
}
</style>
