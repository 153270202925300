<template>
  <div>
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group label="Unidade de destino" label-for="unidade_destino" label-class="float-left">
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome da unidade de destino do material'
            "
            class="tooltip-icon"
          />
          <GenericSelect
            ref="selectUnidadeDestino"
            name="id_unidade_destino_procedimento"
            labelKey="nome"
            v-model="procedimento.id_unidade_destino"
            route="unidade"
            v-validate="{ required: true }"
            :state="validateState('id_unidade_destino_procedimento')"
          ></GenericSelect>
        </b-form-group>

        <b-form-group
          label="Data do procedimento"
          label-for="data_procedimento"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe a data em que ocorrerá o procedimento'
            "
            class="tooltip-icon"
          />
          <b-form-datepicker
            id="data_procedimento"
            class="invision-input"
            placeholder="Data"
            v-model="procedimento.data_procedimento"
            name="data_procedimento"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            locale="pt"
            v-validate="{ required: true }"
            :state="validateState('data_procedimento')"
          >
          </b-form-datepicker>
        </b-form-group>

        <b-form-group
          v-if="materialModel.id_tipo_material === 4"
          label="Aviso*"
          label-for="aviso"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o aviso'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="aviso_procedimento"
            v-model="procedimento.aviso"
            autocomplete="off"
            class="invision-input"
            name="aviso_procedimento"
            v-validate="{ required: true }"
            :state="validateState('aviso_procedimento')"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12" md="6" lg="6" xl="6">
        <b-form-group
          v-if="materialModel.id_tipo_material === 4"
          label="Paciente"
          label-for="paciente"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o nome do paciente'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="paciente"
            v-model="procedimento.paciente"
            autocomplete="off"
            class="invision-input"
            name="paciente"
            v-validate="{ required: true }"
            :state="validateState('paciente')"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-else
          label="Número de peças*"
          label-for="numero_pecas"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Se o valor for alterado, será modificado o cadastro da quantidade de peças do material'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="numero_pecas"
            type="number"
            v-model="quantidade_itens"
            autocomplete="off"
            class="invision-input"
            name="numero_pecas"
            min="1"
            v-validate="{ required: true }"
            :state="validateState('numero_pecas')"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Horário do procedimento*"
          label-for="horario_procedimento"
        >
          <HourInput
            name="input_hour"
            v-b-tooltip.hover.right="'Informe o horário que ocorrerá o procedimento'"
            v-validate="{ required: true }"
            :state="validateState('input_hour')"
            :isBusy="false"
            v-model="procedimento.horario_procedimento"
          />
        </b-form-group>

        <b-form-group
          v-if="materialModel.id_tipo_material === 4"
          label="Protocolo*"
          label-for="protocolo"
          label-class="float-left"
        >
          <info-icon
            v-b-tooltip.hover.right="
              'Informe o protocolo'
            "
            class="tooltip-icon"
          />
          <b-form-input
            id="protocolo"
            v-model="procedimento.protocolo"
            autocomplete="off"
            class="invision-input"
            name="protocolo"
            v-validate="'required'"
            :state="validateState('protocolo')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="mr-0">
        <b-button
          type="button"
          variant="none"
          class="danger-outline-button"
          @click="cancelar"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="none"
          class="btn primary-contained-button"
          style="float: right"
          @click="confirmar"
          >Confirmar</b-button
        >
    </div>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';
import HourInput from '@/components/Utils/Hour';

export default {
  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
    this.procedimento = { ...this.materialModel.procedimento };
    this.quantidade_itens = this.materialModel.quantidade_itens;
  },
  components: {
    GenericSelect,
    HourInput,
  },
  props: {
    materialModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantidade_itens: null,
      procedimento: {
        id_unidade_destino: null,
        data_procedimento: null,
        aviso: '',
        paciente: '',
        horario_procedimento: null,
        protocolo: null,
      },
    };
  },
  methods: {
    async confirmar() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        if (this.quantidade_itens) {
          this.$emit(
            'update-material',
            'quantidade_itens',
            this.quantidade_itens,
          );
        }
        this.$emit('update-material', 'procedimento', this.procedimento);
        this.$emit('close-modal');
      }
    },

    cancelar() {
      this.procedimento = { ...this.materialModel.procedimento };
      this.quantidade_itens = this.materialModel.quantidade_itens;
      this.$emit('close-modal');
    },
    validateState(ref) {
      if (
        this.veeFields[ref]
        && (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
</script>
