<template>
  <div>
    <b-form-group :label="label" :label-for="name">
      <b-form-radio-group
        :name="name"
        :id="name"
        v-model="selectedOption"
        :options="Options"
        :disabled="disabled"
        @change="valueChanged"
        :state="state"

        ></b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedOption: this.value,
      Options: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
    };
  },
  watch: {
    value() {
      this.selectedOption = this.value;
    },
  },
  methods: {
    valueChanged(e) {
      if (!this.disabled) {
        this.$emit('input', e);
      }
    },
  },
};
</script>

<style></style>
