var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Unidade de destino","label-for":"unidade_destino","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome da unidade de destino do material'
          ),expression:"\n            'Informe o nome da unidade de destino do material'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('GenericSelect',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],ref:"selectUnidadeDestino",attrs:{"name":"id_unidade_destino_procedimento","labelKey":"nome","route":"unidade","state":_vm.validateState('id_unidade_destino_procedimento')},model:{value:(_vm.procedimento.id_unidade_destino),callback:function ($$v) {_vm.$set(_vm.procedimento, "id_unidade_destino", $$v)},expression:"procedimento.id_unidade_destino"}})],1),_c('b-form-group',{attrs:{"label":"Data do procedimento","label-for":"data_procedimento","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe a data em que ocorrerá o procedimento'
          ),expression:"\n            'Informe a data em que ocorrerá o procedimento'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"data_procedimento","placeholder":"Data","name":"data_procedimento","date-format-options":{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },"locale":"pt","state":_vm.validateState('data_procedimento')},model:{value:(_vm.procedimento.data_procedimento),callback:function ($$v) {_vm.$set(_vm.procedimento, "data_procedimento", $$v)},expression:"procedimento.data_procedimento"}})],1),(_vm.materialModel.id_tipo_material === 4)?_c('b-form-group',{attrs:{"label":"Aviso*","label-for":"aviso","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o aviso'
          ),expression:"\n            'Informe o aviso'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"aviso_procedimento","autocomplete":"off","name":"aviso_procedimento","state":_vm.validateState('aviso_procedimento')},model:{value:(_vm.procedimento.aviso),callback:function ($$v) {_vm.$set(_vm.procedimento, "aviso", $$v)},expression:"procedimento.aviso"}})],1):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6","xl":"6"}},[(_vm.materialModel.id_tipo_material === 4)?_c('b-form-group',{attrs:{"label":"Paciente","label-for":"paciente","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o nome do paciente'
          ),expression:"\n            'Informe o nome do paciente'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"paciente","autocomplete":"off","name":"paciente","state":_vm.validateState('paciente')},model:{value:(_vm.procedimento.paciente),callback:function ($$v) {_vm.$set(_vm.procedimento, "paciente", $$v)},expression:"procedimento.paciente"}})],1):_c('b-form-group',{attrs:{"label":"Número de peças*","label-for":"numero_pecas","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Se o valor for alterado, será modificado o cadastro da quantidade de peças do material'
          ),expression:"\n            'Se o valor for alterado, será modificado o cadastro da quantidade de peças do material'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"invision-input",attrs:{"id":"numero_pecas","type":"number","autocomplete":"off","name":"numero_pecas","min":"1","state":_vm.validateState('numero_pecas')},model:{value:(_vm.quantidade_itens),callback:function ($$v) {_vm.quantidade_itens=$$v},expression:"quantidade_itens"}})],1),_c('b-form-group',{attrs:{"label":"Horário do procedimento*","label-for":"horario_procedimento"}},[_c('HourInput',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Informe o horário que ocorrerá o procedimento'),expression:"'Informe o horário que ocorrerá o procedimento'",modifiers:{"hover":true,"right":true}},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"input_hour","state":_vm.validateState('input_hour'),"isBusy":false},model:{value:(_vm.procedimento.horario_procedimento),callback:function ($$v) {_vm.$set(_vm.procedimento, "horario_procedimento", $$v)},expression:"procedimento.horario_procedimento"}})],1),(_vm.materialModel.id_tipo_material === 4)?_c('b-form-group',{attrs:{"label":"Protocolo*","label-for":"protocolo","label-class":"float-left"}},[_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
            'Informe o protocolo'
          ),expression:"\n            'Informe o protocolo'\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"tooltip-icon"}),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"invision-input",attrs:{"id":"protocolo","autocomplete":"off","name":"protocolo","state":_vm.validateState('protocolo')},model:{value:(_vm.procedimento.protocolo),callback:function ($$v) {_vm.$set(_vm.procedimento, "protocolo", $$v)},expression:"procedimento.protocolo"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"mr-0"},[_c('b-button',{staticClass:"danger-outline-button",attrs:{"type":"button","variant":"none"},on:{"click":_vm.cancelar}},[_vm._v(" Cancelar ")]),_c('b-button',{staticClass:"btn primary-contained-button",staticStyle:{"float":"right"},attrs:{"type":"button","variant":"none"},on:{"click":_vm.confirmar}},[_vm._v("Confirmar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }