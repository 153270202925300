import genericRequest from './genericRequest';
import LoginService from './login';
import store from '../store';

const loggRoute = 'logg';

const logger = (origin, message) => {
  const usuario = LoginService.getUser();
  const unidade = store.getters.currentUnidade;
  let body = {};

  const defaultContent = {
    id_unidade: unidade && unidade.id_unidade,
    id_usuario: usuario && usuario.id_usuario,
  };

  body = { ...defaultContent };

  genericRequest.create({ origin, message, body }, loggRoute);
};

export default logger;
